import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import CarCards from './CarCards';
import { getCars, addCar, uploadCarImage, updateCar, deleteCar, updateCarImage } from '../../api/admin';

interface Car {
  make: string;
  model: string;
  price_per_day: number;
  available: boolean;
  transmission_type: string;
  fuel_type: string;
  mileage: number;
  image?: { img_id: string; data: string };
  _id?: string;
  created_at?: string;
  updated_at?: string;
}

const ManageCars = () => {
  const [car, setCar] = useState<Car>({
    make: '',
    model: '',
    price_per_day: 0,
    available: true,
    transmission_type: 'Manual',
    fuel_type: 'Petrol',
    mileage: 0,
    image: { img_id: '', data: '' },
  });

  const [carList, setCarList] = useState<Car[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);

  useEffect(() => {
    loadCars();
  }, []);

  const loadCars = async () => {
    try {
      const data = await getCars();
      setCarList(data);
    } catch (error) {
      console.error('Error loading cars:', error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name === 'mileage' || name === 'price_per_day') {
      setCar({ ...car, [name]: Number(value) });
    } else if (name === 'available') {
      setCar({ ...car, available: value === 'true' });
    } else {
      setCar({ ...car, [name === 'type' ? 'transmission_type' : name === 'rent' ? 'price_per_day' : name]: value });
    }
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
      const reader = new FileReader();
      
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const base64Data = base64String.split(',')[1];
        setCar({ ...car, image: { img_id: '', data: base64Data } });
      };
      
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (editIndex !== null && car._id) {
        const updatedCar = await updateCar(car._id, car);
        if (selectedFile && car.image?.img_id) {
          const formData = new FormData();
          formData.append('image', selectedFile);
          await updateCarImage(car._id, car.image.img_id, formData);
        }
        const updatedCars = carList.map((c) =>
          c._id === car._id ? updatedCar : c
        );
        setCarList(updatedCars);
        setEditIndex(null);
      } else {
        const response = await addCar(car);
        const newCarId = response._id;

        if (selectedFile && newCarId) {
          const formData = new FormData();
          formData.append('image', selectedFile);
          await uploadCarImage(newCarId, formData);
        }

        setCarList([...carList, response]);
      }
      handleCancel();
    } catch (error) {
      console.error('Error saving car:', error);
    }
  };

  const handleEdit = (index: number) => {
    setCar(carList[index]);
    setEditIndex(index);
    setShowForm(true);
  };

  const handleDelete = async (index: number) => {
    if (window.confirm('Are you sure you want to delete this car?')) {
      try {
        const carToDelete = carList[index];
        if (carToDelete._id) {
          await deleteCar(carToDelete._id);
          const updatedCars = carList.filter((_, i) => i !== index);
          setCarList(updatedCars);
        }
      } catch (error) {
        console.error('Error deleting car:', error);
      }
    }
  };

  const handleCancel = () => {
    setEditIndex(null);
    setShowForm(false);
    setCar({
      make: '',
      model: '',
      price_per_day: 0,
      available: true,
      transmission_type: 'Manual',
      fuel_type: 'Petrol',
      mileage: 0,
      image: { img_id: '', data: '' },
    });
    setSelectedFile(null);
  };

  return (
    <div className="container-fluid px-3 px-sm-4 px-md-5 mt-4 mt-sm-5">
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mb-4">
        <h1 className="h2 mb-3 mb-sm-0" style={{fontWeight: "bold"}}>Manage Cars</h1>
        <button className="btn btn-primary px-4 py-2 fw-bold w-40 w-sm-auto" onClick={() => setShowForm(true)}>
          Add New Car
        </button>
      </div>

      {showForm && (
        <form onSubmit={handleSubmit} className="border p-3 p-sm-4 bg-light rounded shadow mx-auto mb-5" style={{maxWidth: '1000px', backgroundColor: '#f8f9fa'}}>
          <h2 className="text-center mb-3 h3" style={{fontWeight: "bold"}}>{editIndex !== null ? 'Edit Car Details' : 'Add New Car'}</h2>
          <div className="row g-3">
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label htmlFor="make" className="mb-1 fw-bold">Car Make</label>
                <input
                  type="text"
                  className="form-control p-2"
                  id="make"
                  name="make"
                  value={car.make}
                  onChange={handleChange}
                  required
                  placeholder="Enter car make"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="model" className="mb-1 fw-bold">Car Model</label>
                <input
                  type="text"
                  className="form-control p-2"
                  id="model"
                  name="model"
                  value={car.model}
                  onChange={handleChange}
                  required
                  placeholder="Enter car model"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="transmission_type" className="mb-1 fw-bold">Car Type</label>
                <select
                  className="form-control p-2"
                  id="transmission_type"
                  name="type"
                  value={car.transmission_type}
                  onChange={handleChange}
                >
                  <option value="Manual">Manual</option>
                  <option value="Automatic">Automatic</option>
                </select>
              </div>
              <div className="form-group mb-2">
                <label htmlFor="mileage" className="mb-1 fw-bold">Mileage (km/l)</label>
                <input
                  type="number"
                  className="form-control p-2"
                  id="mileage"
                  name="mileage"
                  value={car.mileage}
                  onChange={handleChange}
                  required
                  placeholder="Enter mileage"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="fuel_type" className="mb-1 fw-bold">Fuel Type</label>
                <select
                  className="form-control p-2"
                  id="fuel_type"
                  name="fuel_type"
                  value={car.fuel_type}
                  onChange={handleChange}
                >
                  <option value="Petrol">Petrol</option>
                  <option value="Diesel">Diesel</option>
                </select>
              </div>
              <div className="form-group mb-2">
                <label htmlFor="price_per_day" className="mb-2 fw-bold">Per Day Rent (₹)</label>
                <input
                  type="number"
                  className="form-control p-2"
                  id="price_per_day"
                  name="rent"
                  value={car.price_per_day}
                  onChange={handleChange}
                  required
                  placeholder="Enter rent per day"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="available" className="mb-1 fw-bold">Availability Status</label>
                <select
                  className="form-control p-2"
                  id="available"
                  name="available"
                  value={car.available.toString()}
                  onChange={handleChange}
                >
                  <option value="true">Available</option>
                  <option value="false">Not Available</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group h-100 d-flex flex-column justify-content-center align-items-center">
                <label htmlFor="image" className="mb-2 fw-bold">Car Image</label>   
                {car.image?.data && (
                  <img 
                    src={`data:image/jpeg;base64,${car.image.data}`} 
                    alt="Preview" 
                    className="mt-3 rounded shadow img-fluid" 
                    style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain', margin: '20px 0' }} 
                  />
                )}
                <input
                  type="file"
                  className="form-control"
                  id="image"
                  name="image"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-center">
              <button type="submit" className="btn btn-primary px-4 py-2 fw-bold shadow-sm me-2 mb-2 mb-sm-0">
                {editIndex !== null ? 'Update Car' : 'Add Car'}
              </button>
              <button type="button" className="btn btn-secondary px-4 py-2 fw-bold shadow-sm me-2 mb-2 mb-sm-0" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3 g-md-4">
        {carList.map((car, index) => (
          <div className="col" key={car._id}>
            <CarCards car={car} index={index} onEdit={handleEdit} onDelete={handleDelete} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageCars;