// src/components/Steps.js

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/BookingSteps.css'; // For custom styles
import background2 from '../assets/background/background2.jpg'
import Arrow from '../assets/images/calendar.png'
import Arrow1 from '../assets/images/navigation.png'
import Arrow2 from '../assets/images/calendar (1).png'

const BookingSteps = () => {
  return (
    <div className="container-fluid steps-container d-flex flex-column justify-content-center align-items-center bg-white pb-4">
      <div className="text-center mb-5">
        <h5 className="text-uppercase" style={{marginTop:"5rem"}}>How It Works</h5>
        <h2 className="fw-bold py-2">Rent With Quick, Easy, and Hassle-free Steps</h2>
      </div>
      <div className="row justify-content-center">
        {/* Step 1 */}
        <div className="col-md-3 mb-5 text-center" style={{marginRight:"20px", marginLeft:"20px", width: '300px'}}>
          <div className="step-icon" style={{ borderRadius: '10%', height: '90px', width: '90px'}}>
            <img src={Arrow1} alt="Choose Location" height={60} width={60} />
          </div>
          <h4 className="mt-3 py-2">Choose A Location</h4>
          <p className="text-muted">
            Choose your preferred location <br/>
            to rent a car. Pick the spot that suits <br/>your travel needs best.
          </p>
        </div>

        {/* <div className="col-md-3">
          <img src={Arrow} alt="Choose Location" height={40}/>
        </div> */}

        {/* Step 2 */}
        <div className="col-md-3 text-center" style={{marginRight:"20px", marginLeft:"20px", width: '300px'}}>
          <div className="step-icon" style={{ backgroundColor: 'orange', borderRadius: '10%', height: '90px', width: '90px'}}>
            <img src={Arrow} alt="Pick-Up Date" height={60} width={60}/>
          </div>
          <h4 className="mt-3 py-2">Pick-Up Date</h4>
          <p className="text-muted">
            Choose the date when you'd like to pick up your rental car. We'll make sure it's ready and waiting for you!
          </p>
        </div>

        {/* Step 3 */}
        <div className="col-md-3 text-center" style={{marginRight:"20px", marginLeft:"20px", width: '300px'}}>
          <div className="step-icon" style={{ borderRadius: '10%', height: '90px', width: '90px'}}>
            <img src={Arrow2} alt="Book A Car" height={60} width={60}/>
          </div>
          <h4 className="mt-3 py-2">Book A Car</h4>
          <p className="text-muted">
            Ready to hit the road? Book your car now and embark on an unforgettable adventure. Safe travels!
          </p>
        </div>
      </div>
    </div>
  );
};

export default BookingSteps;
