import React, { useEffect, useState } from 'react';
import { createBooking, getCars, updateBooking } from '../../api/admin';
import moment from 'moment';

interface Car {
  _id: string;
  make: string;
  model: string;
  transmission_type: string;
  fuel_type: string;
}

interface Booking {
  _id: string;
  car_id: string;
  start_date: string;
  end_date: string;
  pick_up_location: string;
  drop_off_location: string;
  status: string;
  name: string;
  email: string;
  phone: string;
  license_number: string;
  car_registration_number: string;
  notes: string;
  total_amount: number;
}

interface AdminCreateBookingFormProps {
  newBooking: Booking;
  editingBooking: Booking | null;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleCancel: () => void;
}

const AdminCreateBookingForm: React.FC<AdminCreateBookingFormProps> = ({
  newBooking,
  editingBooking,
  handleChange,
  handleSubmit,
  handleCancel,
}) => {
  const [cars, setCars] = useState<Car[]>([]);

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const carsData = await getCars();
        setCars(carsData);
      } catch (error) {
        console.error('Error fetching cars:', error);
      }
    };

    fetchCars();
  }, []);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (editingBooking) {
        await updateBooking(editingBooking._id, newBooking);
      } else {
        const bookingData = {
          car_id: newBooking.car_id,
          start_date: newBooking.start_date,
          end_date: newBooking.end_date,
          pick_up_location: newBooking.pick_up_location,
          drop_off_location: newBooking.drop_off_location,
          status: newBooking.status,
          name: newBooking.name,
          email: newBooking.email,
          phone: newBooking.phone,
          license_number: newBooking.license_number,
          car_registration_number: newBooking.car_registration_number,
          notes: newBooking.notes,
          total_amount: newBooking.total_amount,
        }
        console.log('Booking Data:', bookingData);
        await createBooking(bookingData);
      }
      handleSubmit(e);
    } catch (error) {
      console.error('Error submitting booking:', error);
    }
  };

  return (
    <>
      {/* Booking Form */}
      {(editingBooking || newBooking) && (
        <form onSubmit={handleFormSubmit} className="border p-3 p-md-4 bg-light rounded shadow mb-4 w-100">
          <h2 className="text-center mb-4">{editingBooking ? 'Edit Booking' : 'Create New Booking'}</h2>
          <div className="row g-3">
            {/* Left Side Form Inputs */}
            <div className="col-12 col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="name" className="form-label">Customer Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  value={newBooking.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="email" className="form-label">Customer Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  value={newBooking.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="phone" className="form-label">Mobile Number</label>
                <input
                  maxLength={10}
                  type="text"
                  id="phone"
                  name="phone"
                  className="form-control"
                  value={newBooking.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              
              <div className="form-group mb-3">
                <label htmlFor="start_date" className="form-label">Pick-up Date</label>
                <input
                  type="datetime-local"
                  id="start_date"
                  name="start_date"
                  className="form-control"
                  value={moment(newBooking.start_date).format('YYYY-MM-DDTHH:mm')}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="pick_up_location" className="form-label">Pickup Location</label>
                <input
                  type="text"
                  id="pick_up_location"
                  name="pick_up_location"
                  className="form-control"
                  value={newBooking.pick_up_location}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="total_amount" className="form-label">Total Amount</label>
                <input
                  type="number"
                  id="total_amount"
                  name="total_amount"
                  className="form-control"
                  value={newBooking.total_amount}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="status" className="form-label">Status</label>
                <select
                  id="status"
                  name="status"
                  className="form-select"
                  value={newBooking.status}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select status</option>
                  <option value="Pending">Pending</option>
                  <option value="Paid">Paid</option>
                  <option value="Rejected">Rejected</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
            </div>

            {/* Right Side Form Inputs */}
            <div className="col-12 col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="end_date" className="form-label">Drop-off Date</label>
                <input
                  type="datetime-local"
                  id="end_date"
                  name="end_date"
                  className="form-control"
                  value={moment(newBooking.end_date).format('YYYY-MM-DDTHH:mm')}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="drop_off_location" className="form-label">Dropoff Location</label>
                <input
                  type="text"
                  id="drop_off_location"
                  name="drop_off_location"
                  className="form-control"
                  value={newBooking.drop_off_location}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="car_id" className="form-label">Select Car</label>
                <select
                  id="car_id"
                  name="car_id"
                  className="form-select"
                  value={newBooking.car_id}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a car</option>
                  {cars.map((car) => (
                    <option key={car._id} value={car._id}>
                      {`${car.make} ${car.model} - ${car.transmission_type} - ${car.fuel_type}`}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="car_registration_number" className="form-label">Car Registration No.</label>
                <input
                  type="text"
                  id="car_registration_number"
                  name="car_registration_number"
                  className="form-control"
                  value={newBooking.car_registration_number}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="license_number" className="form-label">Customer License No.</label>
                <input
                  type="text"
                  id="license_number"
                  name="license_number"
                  className="form-control"
                  value={newBooking.license_number}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="notes" className="form-label">Notes</label>
                <textarea
                  id="notes"
                  name="notes"
                  className="form-control"
                  value={newBooking.notes}
                  onChange={handleChange}
                  rows={3}
                ></textarea>
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="form-group d-flex flex-wrap gap-2 justify-content-center mt-4">
            <button type="submit" className="btn btn-success">
              {editingBooking ? 'Update Booking' : 'Create Booking'}
            </button>
            <button type="button" className="btn btn-secondary" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default AdminCreateBookingForm;