// src/components/CarCards.js

import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/ExploreCars.css';
import Fuel from '../assets/icons/fuel.png'
import Transmission from '../assets/icons/transmission.png'
import Mileage from '../assets/icons/mileage.png'
import { Modal } from 'antd';
import BookingForm from './BookingForm';
import { getCars, getCarImages } from '../api/user';

interface Car {
  _id: string;
  make: string;
  model: string;
  price_per_day: number;
  mileage: number;
  transmission_type: string;
  fuel_type: string;
  available: boolean;
  created_at: string;
  updated_at: string;
  images?: Array<{ data: string; img_id: string }>;
}

const ExploreCars = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCar, setSelectedCar] = useState<Car | null>(null);
  const [cars, setCars] = useState<Car[]>([]);

  const showModal = (car: Car) => {
    setSelectedCar(car);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedCar(null);
  };

  useEffect(() => {
    const loadCars = async () => {
      try {
        const data = await getCars();
        const carsWithImages = await Promise.all(
          data.map(async (car: Car) => {
            const images = await getCarImages(car._id);
            return { ...car, images };
          })
        );
        setCars(carsWithImages);
      } catch (error) {
        console.error('Error loading cars:', error);
      }
    };
    loadCars();
  }, []);

  return (
    <div className="container py-5" >
     <div className="d-flex justify-content-center my-4">
      <h1 style={{fontWeight: "bold"}}>Explore Our Top Deals</h1>
    </div>

      <div className="row">
        {cars.map((car) => (
          <div key={car._id} className="col-md-3 mb-3">
            <div className="px-3 py-3" style={{ backgroundColor: "white", height: "100%", borderRadius: "1rem" }}>
              <div style={{marginBottom: "1rem"}}>
                <img src={car.images && car.images.length > 0 ? `data:image/jpeg;base64,${car.images[0].data}` : 'https://imgd-ct.aeplcdn.com/664x415/n/cw/ec/44709/fortuner-exterior-left-front-three-quarter.jpeg?q=80'} className="card-img-top" alt={`${car.make} ${car.model}`} style={{ borderRadius: "1.5rem" }}/>
              </div>              
              <div className="card-body">
                <h5 className="card-title">{car.make} {car.model}</h5>
                <p className="text">₹{car.price_per_day}/day</p>
                <div className="d-flex justify-content-between text-muted mb-3">
           <div>
            <img
              src={Mileage}
              alt="Mileage"
              style={{ width: "20px", height: "20px" }}
            />
            <span style={{marginLeft:"0.3rem"}}>{car.mileage} km</span>
            </div>
            <div>
            <img
              src={Transmission}
              alt="Transmission"
              style={{ width: "20px", height: "20px" }}
            />      
                  <span style={{marginLeft:"0.3rem"}}>{car.transmission_type}</span>
                  </div>
                  <div>
                  <img
              src={Fuel}
              alt="Fuel Type"
              style={{ width: "20px", height: "20px" }}
            />  
                  <span style={{marginLeft:"0.3rem"}}>{car.fuel_type}</span>
                  </div>
                </div>
                <button className="btn btn-primary w-100" style={{fontWeight:"bold"}} onClick={() => showModal(car)} disabled={!car.available}>
                  {car.available ? 'Rent Now' : 'Not Available'}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={"75%"}
        style={{ 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        bodyStyle={{
          backgroundColor: '#f5f5f5',
          padding: '1rem',
        }}
      >
        <div className="row d-flex align-items-center">
          <div className="col-md-4">
            {selectedCar && (
              <div className="px-3 py-3 d-flex flex-column justify-content-center" style={{ backgroundColor: "white", height: "60%", borderRadius: "0.5rem" }}>
                <div style={{marginBottom: "0.5rem"}}>
                  <img src={selectedCar.images && selectedCar.images.length > 0 ? selectedCar.images[0].data : 'https://imgd-ct.aeplcdn.com/664x415/n/cw/ec/44709/fortuner-exterior-left-front-three-quarter.jpeg?q=80'} className="card-img-top" alt={`${selectedCar.make} ${selectedCar.model}`} style={{ borderRadius: "0.75rem" }}/>
                </div>
                <div className="card-body p-2">
                  <h6 className="card-title">{selectedCar.make} {selectedCar.model}</h6>
                  <p className="text small mb-2">₹{selectedCar.price_per_day}/day</p>
                  <div className="d-flex justify-content-between text-muted small">
                    <div>
                      <img src={Mileage} alt="Mileage" style={{ width: "16px", height: "16px" }} />
                      <span style={{marginLeft:"0.2rem"}}>{selectedCar.mileage} km</span>
                    </div>
                    <div>
                      <img src={Transmission} alt="Transmission" style={{ width: "16px", height: "16px" }} />      
                      <span style={{marginLeft:"0.2rem"}}>{selectedCar.transmission_type}</span>
                    </div>
                    <div>
                      <img src={Fuel} alt="Fuel Type" style={{ width: "16px", height: "16px" }} />  
                      <span style={{marginLeft:"0.2rem"}}>{selectedCar.fuel_type}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-8 d-flex align-items-center">
            <BookingForm carId={selectedCar?._id || ''} onEnquire={handleCancel} />
          </div>
        </div>
      </Modal>    
    </div>
  );
};

export default ExploreCars;