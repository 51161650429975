import axiosInstance from './axiosInstance';

export const login = async (username: string, password: string) => {
  try {
    const formData = new URLSearchParams();
    formData.append('username', username);
    formData.append('password', password);

    const response = await axiosInstance.post('/admin/login', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

// Cars
export const getCars = async () => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axiosInstance.get(`/admin/cars`, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const getCar = async (car_id: string) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axiosInstance.get(`/admin/cars/${car_id}`, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const addCar = async (carData: any) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axiosInstance.post(`/admin/cars`, carData, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
};

export const deleteCar = async (car_id: any) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axiosInstance.delete(`/admin/cars/${car_id}`, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
};

export const updateCar = async (car_id: any, carData: any) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axiosInstance.put(`/admin/cars/${car_id}`, carData, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
};

// Car Images
export const getCarImages = async (car_id: string) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axiosInstance.get(`/admin/cars/${car_id}/images`, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error){
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const uploadCarImage = async (car_id: string, imageData: any) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axiosInstance.post(`/admin/cars/${car_id}/image`, imageData, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
};

export const updateCarImage = async (car_id: string, image_id: string, imageData: any) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axiosInstance.put(`/admin/cars/${car_id}/image/${image_id}`, imageData, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
};

export const deleteCarImage = async (car_id: string, image_id: string) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axiosInstance.delete(`/admin/cars/${car_id}/image/${image_id}`, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
};

// Bookings
export const getBookings = async () => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axiosInstance.get(`/admin/bookings`, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error){
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const createBooking = async (bookingData: any) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axiosInstance.post(`/admin/bookings`, bookingData, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
};

export const getBooking = async (booking_id: string) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axiosInstance.get(`/admin/bookings/${booking_id}`, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error){
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const updateBooking = async (booking_id: string, bookingData: any) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axiosInstance.put(`/admin/bookings/${booking_id}`, bookingData, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
};

export const deleteBooking = async (booking_id: string) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axiosInstance.delete(`/admin/bookings/${booking_id}`, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
};