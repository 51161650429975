import React, { useRef } from "react";
import Navbar from "../components/Navbar";
import WebsiteFooter from "../components/WebsiteFooter";
import Header from "../components/Header";
import BookingSteps from "../components/BookingSteps";
import Services from "../components/Services";
import ExploreCars from "../components/ExploreCars";
import Sidebar from "../components/Sidebar";
import Reviews from "../components/Reviews";

export const Home: React.FC = () => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const servicesRef = useRef<HTMLDivElement | null>(null);
  const exploreCarsRef = useRef<HTMLDivElement | null>(null);

  const scrollToHeader = () => {
    headerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToServices = () => {
    servicesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToExploreCars = () => {
    exploreCarsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div style={{ backgroundColor: "#e5e5e5" }}>
      <Navbar
        scrollToHeader={scrollToHeader}
        scrollToServices={scrollToServices}
        scrollToExploreCars={scrollToExploreCars}
      />
      <div ref={headerRef} style={{ marginTop: "80px" }}>
        <Header />
      </div>
      <BookingSteps />
      <div ref={exploreCarsRef}>
        <ExploreCars />
      </div>
      <Sidebar />
      <div ref={servicesRef}>
        <Services />
      </div>
    {/*  <Reviews /> */}
      <WebsiteFooter
        scrollToServices={scrollToServices}
        scrollToExploreCars={scrollToExploreCars}
      />
    </div>
  );
};
