// src/components/Header.js
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import thar from '../assets/images/thar2.png';
import '../styles/Header.css'; // Ensure this path matches where your CSS is stored

const Header = () => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className="container py-5">
      <div className="row align-items-center">
        {/* First Column: Heading and Details */}
        <div className="col-lg-5 col-md-6 mb-5 mb-md-0">
          <h1 className="display-4 mb-4" style={{ fontWeight: 'bold', fontSize: 'clamp(2rem, 4vw, 3rem)' }}>
            Welcome to Our <br />Car Rental Service
          </h1>
          <div
            style={{
              height: '0.7rem',
              width: '6rem',
              backgroundColor: 'orange',
              borderRadius: '5rem',
              marginBottom: '1rem',
            }}
          ></div>
          <p className="pe-lg-4" style={{ maxWidth: '100%' }}>
            Find the best car rental deals with a wide range of vehicles to suit your needs. Enjoy affordable rates, flexible options, and exceptional service for a seamless rental experience.
          </p>
          <div className="mt-4">
            <button className="btn btn-primary" style={{ 
              width: 'min(100%, 15rem)', 
              padding: '1rem',
              fontSize: 'clamp(1.1rem, 2vw, 1.5rem)', 
              fontWeight: 'bold' 
            }}>
              Book Now
            </button>
          </div>
        </div>

        {/* Second Column: Background and Car Image */}
        <div className="col-lg-7 col-md-6">
          <div
            style={{
              position: 'relative',
              width: '100%',
              paddingBottom: '75%',
              minHeight: '300px',
            }}
          >
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: '15%',
                width: '50%',
                height: '70%',
                backgroundColor: 'orange',
                borderTopLeftRadius: 'clamp(2rem, 4vw, 5rem)',
                borderTopRightRadius: 'clamp(2rem, 4vw, 5rem)',
              }}
            ></div>
            <img 
              src={thar} 
              alt="Car"
              className={`img-fluid ${loaded ? 'slide-in' : ''}`}
              onLoad={() => setLoaded(true)}
              style={{
                position: 'absolute',
                top: '20%',
                left: '5%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '100%',
                height: 'auto',
                zIndex: 1
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
