import React from 'react';
import { Layout, Typography, Space, Row, Col } from 'antd';
import { FacebookOutlined, TwitterOutlined, InstagramOutlined, PhoneOutlined, MailOutlined, GlobalOutlined } from '@ant-design/icons';

const { Footer } = Layout;
const { Text } = Typography;

interface WebsiteFooterProps {
  scrollToServices: () => void;
  scrollToExploreCars: () => void;
}

const WebsiteFooter: React.FC<WebsiteFooterProps> = ({
  scrollToServices,
  scrollToExploreCars,
}) => {
  return (
    <Footer
      style={{
        background: '#3c3365',
        color: '#fff',
        padding: '40px 20px',
        position: 'relative',
        bottom: 0,
        textAlign: 'center',
      }}
    >
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={12} md={8}>
          <Space direction="vertical" size="large" style={{ textAlign: 'center' }}>
            <Text style={{ color: '#fff', fontSize: '16px' }}>© 2024 Your Company. All rights reserved.</Text>
            <Space size="large">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
                <FacebookOutlined style={{ fontSize: '24px' }} />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
                <TwitterOutlined style={{ fontSize: '24px' }} />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
                <InstagramOutlined style={{ fontSize: '24px' }} />
              </a>
            </Space>
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Space direction="vertical" size="large" style={{ textAlign: 'center' }}>
            <Text style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>Contact Us</Text>
            <Space direction="vertical" size="small">
              <Space>
                <PhoneOutlined style={{ color: '#fff' }} />
                <Text style={{ color: '#fff' }}>9999999999</Text>
              </Space>
              <Space>
                <MailOutlined style={{ color: '#fff' }} />
                <Text style={{ color: '#fff' }}>quickcarsrental2024@gmail.com</Text>
              </Space>
              <Space>
                <GlobalOutlined style={{ color: '#fff' }} />
                <Text style={{ color: '#fff' }}>www.quickcarsrental.com</Text>
              </Space>
            </Space>
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Space direction="vertical" size="large" style={{ textAlign: 'center' }}>
            <Text style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>Quick Links</Text>
            <Space direction="vertical" size="small">
              <button
                onClick={scrollToServices}
                style={{
                  color: '#fff',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                About Us
              </button>
              <button
                onClick={scrollToExploreCars}
                style={{
                  color: '#fff',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                Our Cars
              </button>
            </Space>
          </Space>
        </Col>
      </Row>
    </Footer>
  );
};

export default WebsiteFooter;
