// src/components/BestServices.js

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Services.css';
import service from '../assets/images/servicegoa.jpg';
import { BiWallet, BiTag, BiHeadphone } from 'react-icons/bi';

const Services = () => {
  return (
    <div className="container-fluid best-services-container d-flex flex-column justify-content-center align-items-center bg-white" style={{ paddingTop: '5rem', paddingBottom: "5rem" }}>
      <div className="row align-items-center">
        {/* First Column - Car Image */}
        <div className="col-md-6">
          <img
            src={service}
            alt="Luxury Car"
            className="img-fluid rounded shadow-lg hover-zoom"
            height={650} width={650}
            style={{ transition: 'transform 0.3s ease-in-out', margin: '2rem' }}
          />
        </div>

        {/* Second Column - Text Content */}
        <div className="col-md-6">
          <h2 className="text-uppercase fw-bold display-5" style={{ color: "#3c3365"}}>Best Services</h2>
          <h2 className="fw-bold display-5">Feel the best experience with our rental deals</h2>
          <hr className="mb-4" style={{ width: '50px', height: '3px', backgroundColor: '#ff6b00', opacity: '1' }} />

          {/* Service 1 */}
          <div className="d-flex align-items-start mb-4 service-card hover-effect">
            <div className="icon-container me-3 p-3 rounded-circle bg-primary bg-opacity-10">
              <BiWallet size={24} className="text-primary" />
            </div>
            <div>
              <h5 className="mb-1 fw-bold">Deals for every budget</h5>
              <p className="text-muted">Find your perfect ride with our incredible prices on luxury and economy cars.</p>
            </div>
          </div>

          {/* Service 2 */}
          <div className="d-flex align-items-start mb-4 service-card hover-effect">
            <div className="icon-container me-3 p-3 rounded-circle bg-success bg-opacity-10">
              <BiTag size={24} className="text-success" />
            </div>
            <div>
              <h5 className="mb-1 fw-bold">Best price guaranteed</h5>
              <p className="text-muted">We offer the most competitive prices in the market with price match guarantee.</p>
            </div>
          </div>

          {/* Service 3 */}
          <div className="d-flex align-items-start service-card hover-effect">
            <div className="icon-container me-3 p-3 rounded-circle bg-warning bg-opacity-10">
              <BiHeadphone size={24} className="text-warning" />
            </div>
            <div>
              <h5 className="mb-1 fw-bold">Support 24/7</h5>
              <p className="text-muted">Our dedicated support team is available round the clock for your assistance.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;