import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AdminCreateBookingForm from './AdminCreateBookingForm';
import BookingsList from './BookingsList';
import { createBooking, updateBooking } from '../../api/admin';

interface Booking {
  _id: string;
  car_id: string;
  car_registration_number: string;
  start_date: string;
  end_date: string;
  pick_up_location: string;
  drop_off_location: string;
  status: string;
  name: string;
  email: string;
  phone: string;
  license_number: string;
  notes: string;
  total_amount: number;
  created_at: string;
  updated_at: string;
}

const ManageBookings = () => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [newBooking, setNewBooking] = useState<Booking>({
    _id: '',
    car_id: '',
    car_registration_number: '',
    start_date: '',
    end_date: '',
    pick_up_location: '',
    drop_off_location: '',
    status: 'Pending',
    name: '',
    email: '',
    phone: '',
    license_number: '',
    notes: '',
    total_amount: 0,
    created_at: '',
    updated_at: ''
  });
  const [editingBooking, setEditingBooking] = useState<Booking | null>(null);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewBooking({ ...newBooking, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (editingBooking) {
        await updateBooking(editingBooking._id, newBooking);
        const updatedBookings = bookings.map((booking) =>
          booking._id === editingBooking._id ? newBooking : booking
        );
        setBookings(updatedBookings);
        setEditingBooking(null);
      } else {
        const createdBooking = await createBooking(newBooking);
        setBookings([...bookings, createdBooking]);
      }
      resetForm();
      setShowForm(false);
    } catch (error) {
      console.error('Error saving booking:', error);
    }
  };

  const resetForm = () => {
    setNewBooking({
      _id: '',
      car_id: '',
      car_registration_number: '',
      start_date: '',
      end_date: '',
      pick_up_location: '',
      drop_off_location: '',
      status: 'Pending',
      name: '',
      email: '',
      phone: '',
      license_number: '',
      notes: '',
      total_amount: 0,
      created_at: '',
      updated_at: ''
    });
  };

  const handleCancel = () => {
    setEditingBooking(null);
    resetForm();
    setShowForm(false);
  };

  const handleEdit = (booking: Booking) => {
    setEditingBooking(booking);
    setNewBooking(booking);
    setShowForm(true);
  };

  const handleCreateNew = () => {
    setEditingBooking(null);
    resetForm();
    setShowForm(true);
  };

  return (
    <div className="container mt-5">
      {!showForm ? (
        <>
          <div className="d-flex justify-content-between mb-4">
            <input
              type="text"
              className="form-control w-50"
              placeholder="Search bookings..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <button className="btn btn-primary" onClick={handleCreateNew}>
              Create Booking
            </button>
          </div>
          <BookingsList
            searchQuery={searchQuery}
            handleEdit={handleEdit}
          />
        </>
      ) : (
        <AdminCreateBookingForm
          newBooking={newBooking}
          editingBooking={editingBooking}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default ManageBookings;