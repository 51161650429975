import React, { useEffect, useState } from 'react';
import { getCar, getBookings, updateBooking, deleteBooking } from '../../api/admin';
import { FaEdit, FaTrash } from 'react-icons/fa';

interface Booking {
  _id: string;
  car_id: string;
  start_date: string;
  end_date: string;
  pick_up_location: string;
  drop_off_location: string;
  status: string;
  name: string;
  email: string;
  phone: string;
  license_number: string;
  car_registration_number: string;
  notes: string;
  total_amount: number;
  created_at: string;
  updated_at: string;
}

interface Car {
  make: string;
  model: string;
  price_per_day: number;
  available: boolean;
  transmission_type: string;
  fuel_type: string;
  mileage: number;
  _id: string;
  created_at: string;
  updated_at: string;
}

interface BookingsListProps {
  searchQuery: string;
  handleEdit: (booking: Booking) => void;
}

const BookingsList: React.FC<BookingsListProps> = ({
  searchQuery,
  handleEdit,
}) => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [carDetails, setCarDetails] = useState<{ [key: string]: Car }>({});

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const data = await getBookings();
        setBookings(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Error fetching bookings:', error);
        setBookings([]);
      }
    };
    fetchBookings();
  }, []);

  useEffect(() => {
    const fetchCarDetails = async () => {
      for (const booking of bookings) {
        if (booking.car_id && !carDetails[booking.car_id]) {
          try {
            const carData = await getCar(booking.car_id);
            setCarDetails(prev => ({
              ...prev,
              [booking.car_id]: carData
            }));
          } catch (error) {
            console.error('Error fetching car details:', error);
          }
        }
      }
    };
    fetchCarDetails();
  }, [bookings]);

  const handleDelete = async (id: string) => {
    try {
      if (window.confirm('Are you sure you want to delete this booking?\nClick OK to proceed or Cancel to abort.')) {
        await deleteBooking(id);
        const updatedBookings = bookings.filter(booking => booking._id !== id);
        setBookings(updatedBookings);
      }
    } catch (error) {
      console.error('Error deleting booking:', error);
    }
  };

  return (
    <div className="mt-4">
      <h2 className="mb-4">Bookings List</h2>
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="bg-light">
            <tr>
              <th className="align-middle">Customer Name</th>
              <th className="align-middle">Email</th>
              <th className="align-middle">Phone</th>
              <th className="align-middle">Car Details</th>
              <th className="align-middle">Price/Day</th>
              <th className="align-middle">Pickup Location</th>
              <th className="align-middle">Dropoff Location</th>
              <th className="align-middle">Start Date</th>
              <th className="align-middle">End Date</th>
              <th className="align-middle">Status</th>
              <th className="align-middle">License Number</th>
              <th className="align-middle">Car Registration</th>
              <th className="align-middle">Notes</th>
              <th className="align-middle">Total Amount</th>
              <th className="align-middle" style={{ position: 'sticky', right: 0, background: 'white', zIndex: 1 }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {bookings
              .filter(
                (booking) =>
                  booking?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  booking?.email?.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((booking) => {
                const car = carDetails[booking.car_id];
                return (
                  <tr key={booking._id}>
                    <td className="align-middle">{booking.name}</td>
                    <td className="align-middle">{booking.email}</td>
                    <td className="align-middle">{booking.phone}</td>
                    <td className="align-middle">
                      {car ? `${car.make} ${car.model} (${car.transmission_type}, ${car.fuel_type})` : 'Loading...'}
                    </td>
                    <td className="align-middle">{car ? `${car.price_per_day}` : 'Loading...'}</td>
                    <td className="align-middle">{booking.pick_up_location}</td>
                    <td className="align-middle">{booking.drop_off_location}</td>
                    <td className="align-middle">{booking.start_date}</td>
                    <td className="align-middle">{booking.end_date}</td>
                    <td className="align-middle">{booking.status}</td>
                    <td className="align-middle">{booking.license_number}</td>
                    <td className="align-middle">{booking.car_registration_number}</td>
                    <td className="align-middle">{booking.notes}</td>
                    <td className="align-middle">{booking.total_amount}</td>
                    <td className="align-middle" style={{ position: 'sticky', right: 0, background: 'white', zIndex: 1 }}>
                      <div className="d-flex gap-2">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleEdit(booking)}
                        >
                          <FaEdit />
                        </button>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => handleDelete(booking._id)}
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default BookingsList;