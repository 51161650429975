import axiosInstance from './axiosInstance';

export const getCars = async () => {
  try {
    const response = await axiosInstance.get(`/user/cars`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const getCar = async (car_id: string) => {
  try {
    const response = await axiosInstance.get(`/user/cars/${car_id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const getCarImages = async (car_id: string) => {
  try {
    const response = await axiosInstance.get(`/user/cars/${car_id}/images`);
    return response.data;
  } catch (error){
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const createBooking = async (bookingData: any) => {
  try {
    const response = await axiosInstance.post(`/user/bookings`, bookingData);
    return response.data;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
};