import React, { useState } from 'react';
import { Form, Input, Button, DatePicker, TimePicker, Row, Col } from 'antd';
import moment from 'moment';
import { createBooking } from '../api/user';

interface BookingFormProps {
  carId: string;
  onEnquire: Function;
}

const BookingForm: React.FC<BookingFormProps> = ({ carId, onEnquire }) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values: any) => {
    try {
      const formattedData = {
        car_id: carId,
        start_date: moment(`${values.pickupDate.format('YYYY-MM-DD')}T${values.pickupTime.format('HH:mm')}:00`).toISOString(),
        end_date: moment(`${values.dropoffDate.format('YYYY-MM-DD')}T${values.dropoffTime.format('HH:mm')}:00`).toISOString(),
        notes: values.notes || '',
        status: 'Pending',
        name: values.name,
        email: values.email,
        phone: values.phone,
        pick_up_location: values.pickupLocation,
        drop_off_location: values.dropoffLocation,
        license_number: values.licenseNumber || '',
        car_registration_number: values.carRegistrationNumber || '',
        total_amount: values.totalAmount || 0,
      };

      console.log('Formatted Data:', formattedData);

      const response = await createBooking(formattedData);
      onEnquire();
      console.log('Booking created:', response);
    } catch (error) {
      console.error('Error creating booking:', error);
    }
  };

  return (
    <div className="container mt-5" style={{backgroundColor:"#6b5dac", color:"white", borderRadius:"1rem"}}>
      <Row className="justify-content-center">
        <Col xs={20} xl={20} md={20} lg={20}>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
              pickupDate: moment(),
              dropoffDate: moment().add(1, 'days'),
            }}
            style={{ padding: "1rem" }}
          >
            <h3 className="text-center mb-4">Book a Car Quickly!</h3>

            {/* Personal Details */}
            <Row className="gy-1 gx-4">
              <Col xs={12}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: 'Please enter your name!' }]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: 'Please enter your email!' },
                    { type: 'email', message: 'Please enter a valid email!' },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
            </Row>

            <Row className="gy-2 gx-4">
              <Col xs={12}>
                <Form.Item
                  name="phone"
                  label="Phone"
                  rules={[{ required: true, message: 'Please enter your phone number!' }]}
                >
                  <Input placeholder="Phone" />
                </Form.Item>
              </Col>
            </Row>

            {/* Booking Details */}
            <Row className="gy-2 gx-4">
              <Col xs={6}>
                <Form.Item
                  name="pickupDate"
                  label="Pickup Date"
                  rules={[{ required: true, message: 'Please select a pickup date!' }]}
                >
                  <DatePicker
                    format="YYYY-MM-DD"
                    className="w-100"
                    disabledDate={(current) => current && current < moment().startOf('day')}
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item
                  name="pickupTime"
                  label="Pickup Time"
                  rules={[{ required: true, message: 'Please select a pickup time!' }]}
                >
                  <TimePicker format="HH:mm" className="w-100" />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  name="pickupLocation"
                  label="Pickup Location"
                  rules={[{ required: true, message: 'Please enter pickup location!' }]}
                >
                  <Input placeholder="Pickup Location" />
                </Form.Item>
              </Col>
            </Row>

            <Row className="gy-2 gx-4">
              <Col xs={6}>
                <Form.Item
                  name="dropoffDate"
                  label="Dropoff Date"
                  rules={[{ required: true, message: 'Please select a dropoff date!' }]}
                >
                  <DatePicker
                    format="YYYY-MM-DD"
                    className="w-100"
                    disabledDate={(current) => {
                      const pickupDate = form.getFieldValue('pickupDate');
                      return (
                        current &&
                        (current < moment().startOf('day') ||
                          (pickupDate && current < moment(pickupDate).add(1, 'days')))
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item
                  name="dropoffTime"
                  label="Dropoff Time"
                  rules={[{ required: true, message: 'Please select a dropoff time!' }]}
                >
                  <TimePicker format="HH:mm" className="w-100" />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  name="dropoffLocation"
                  label="Dropoff Location"
                  rules={[{ required: true, message: 'Please enter dropoff location!' }]}
                >
                  <Input placeholder="Dropoff Location" />
                </Form.Item>
              </Col>
            </Row>

            <div className="d-flex justify-content-center">
              <Button type="primary" htmlType="submit" className="mt-3">
                Enquire Now
              </Button>
            </div>
          </Form>
        </Col>
      </Row>        

    </div>
  );
};

export default BookingForm;