import React, { useState } from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaPhoneAlt, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import '../styles/Sidebar.css'; // Import custom CSS for styling

const Sidebar = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
  className={`expandable-sidebar d-flex flex-column ${isExpanded ? 'expanded' : ''}`}
  style={{ width: isExpanded ? '50px' : '40px', borderTopLeftRadius:"5rem",  borderBottomLeftRadius:"5rem" }} // Adjust '80px' and '40px' as needed
>
  <div className="toggle-button" onClick={toggleSidebar}>
    {isExpanded ? <FaArrowRight /> : <FaArrowLeft />}
  </div>
  {isExpanded && (
    <ul className="list-unstyled sidebar-content">
      <li className="sidebar-item">
        <a href="tel:+1234567890" className="sidebar-link">
          <FaPhoneAlt />
        </a>
      </li>
      <li className="sidebar-item">
        <a
          href="https://www.facebook.com"
          target="_blank"
          rel="noopener noreferrer"
          className="sidebar-link"
        >
          <FaFacebook />
        </a>
      </li>
      <li className="sidebar-item">
        <a
          href="https://www.twitter.com"
          target="_blank"
          rel="noopener noreferrer"
          className="sidebar-link"
        >
          <FaTwitter />
        </a>
      </li>
      <li className="sidebar-item">
        <a
          href="https://www.instagram.com"
          target="_blank"
          rel="noopener noreferrer"
          className="sidebar-link"
        >
          <FaInstagram />
        </a>
      </li>
    </ul>
  )}
</div>

  );
};

export default Sidebar;
