import Fuel from '../../assets/icons/fuel.png';
import Transmission from '../../assets/icons/transmission.png';
import Mileage from '../../assets/icons/mileage.png';
import { getCarImages } from '../../api/admin';
import { useEffect, useState } from 'react';

interface Car {
    make: string;
    model: string;
    price_per_day: number;
    available: boolean;
    transmission_type: string;
    fuel_type: string;
    mileage: number;
    image?: { img_id: string; data: string }; // Updated image type
    _id?: string;
    created_at?: string;
    updated_at?: string;
}

interface CarImage {
    img_id: string;
    data: string;
}

interface CarCardsProps {
    car: Car;
    index: number;
    onEdit: (index: number) => void;
    onDelete: (index: number) => void;
}

const CarCards = ({ car, index, onEdit, onDelete }: CarCardsProps) => {
    const [carImages, setCarImages] = useState<CarImage[]>([]);

    useEffect(() => {
        const fetchCarImages = async () => {
            if (car._id) {
                const images = await getCarImages(car._id);
                setCarImages(images);
            }
        };
        fetchCarImages();
    }, [car._id]);

    // Use the first image from carImages array if available, else fallback to car.image data
    const carImageSrc = carImages.length > 0
        ? `data:image/jpeg;base64,${carImages[0].data}`
        : car.image
        ? `data:image/jpeg;base64,${car.image.data}`
        : '';

    return (
        <div className="row">
            <div className="mb-3">
                <div
                    className="px-3 py-3"
                    style={{
                        backgroundColor: "white",
                        height: "100%",
                        borderRadius: "1rem",
                        border: car.available ? 'none' : '2px solid orange',
                    }}
                >
                    <div style={{ marginBottom: "1rem" }}>
                        <img
                            src={carImageSrc}
                            alt={`${car.make} ${car.model}`}
                            className="card-img-top"
                            style={{ borderRadius: "1.5rem" }}
                        />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">{car.make} {car.model}</h5>
                        <p className="text">₹{car.price_per_day}/day</p>
                        <div className="d-flex justify-content-between text-muted mb-3">
                            <div>
                                <img
                                    src={Mileage}
                                    alt="Mileage"
                                    style={{ width: "20px", height: "20px" }}
                                />
                                <span style={{ marginLeft: "0.3rem" }}>{car.mileage} km</span>
                            </div>
                            <div>
                                <img
                                    src={Transmission}
                                    alt="Type"
                                    style={{ width: "20px", height: "20px" }}
                                />
                                <span style={{ marginLeft: "0.3rem" }}>{car.transmission_type}</span>
                            </div>
                            <div>
                                <img
                                    src={Fuel}
                                    alt="Fuel Type"
                                    style={{ width: "20px", height: "20px" }}
                                />
                                <span style={{ marginLeft: "0.3rem" }}>{car.fuel_type}</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button
                                className="btn btn-secondary"
                                onClick={() => onEdit(index)}
                                style={{ width: "48%", fontWeight: "bold" }}
                            >
                                Edit
                            </button>
                            <button
                                className="btn btn-danger"
                                onClick={() => onDelete(index)}
                                style={{ width: "48%", fontWeight: "bold" }}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CarCards;