import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import ManageBookings from '../components/AdminManageBookings/ManageBookings';
import ManageCars from '../components/AdminManageCars/ManageCars';
import { useAuth } from '../context/AuthContext';

export const AdminPanel = () => {
  const [activeTab, setActiveTab] = useState("manageBookings");
  const { logout } = useAuth();

  return (
    <div style={{ backgroundColor: '#e5e5e5', minHeight: '100vh' }}>
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            {/* Logo on the left */}
            <a className="navbar-brand" href="#">
              <img src="path-to-your-logo.png" alt="Logo" style={{ width: '50px' }} />
            </a>
            {/* Admin heading at the center */}
            <span className="navbar-text" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
              Admin Panel
            </span>
            {/* Logout Button on the right */}
            <div className="d-flex">
              <button className="btn btn-outline-danger" onClick={logout}>
                Logout
              </button>
            </div>
          </div>
        </nav>
      </div>
      <div className="container mt-4">
        {/* Bootstrap Tabs */}
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "manageBookings" ? "active" : ""}`}
              onClick={() => setActiveTab("manageBookings")}
            >
              Manage Bookings
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "manageCars" ? "active" : ""}`}
              onClick={() => setActiveTab("manageCars")}
            >
              Manage Cars
            </button>
          </li>
        </ul>

        {/* Tab Content */}
        <div className="tab-content mt-3">
          <div className={`tab-pane fade ${activeTab === "manageBookings" ? "show active" : ""}`}>
            <ManageBookings />
          </div>
          <div className={`tab-pane fade ${activeTab === "manageCars" ? "show active" : ""}`}>
            <ManageCars />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
