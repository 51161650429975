import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/Header.css";
import logo from '../assets/images/Quickcarsrental.png';

interface NavbarProps {
  scrollToHeader: () => void;
  scrollToServices: () => void;
  scrollToExploreCars: () => void;
}

const Navbar: React.FC<NavbarProps> = ({
  scrollToHeader,
  scrollToServices,
  scrollToExploreCars,
}) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light custom-navbar bg-primary fixed-top">
      <div className="container-fluid justify-content-between align-items-center "> {/* Use container-fluid for full-width */}
        <div className="d-flex w-100">
          {/* Logo */}
          <a className="navbar-brand d-flex align-items-center" href="/">
            <img
              src={logo}
              alt="Logo"
              className="navbar-logo"
              style={{ height: "70px", width: "80px" }}
            />
            <span style={{ 
              marginLeft: "10px", 
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "clamp(1rem, 2vw, 1.5rem)",
              fontWeight: "bold",
              background: "linear-gradient(120deg, #000000, #FF0000)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textTransform: "uppercase",
              letterSpacing: "1px",
              display: "inline-block",
              maxWidth: "80%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}>
              QUICK CARS RENTAL
            </span>
          </a>
          {/* Navbar toggler for mobile view */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
       

        {/* Centered Links */}
        <div
          className="collapse navbar-collapse"
          id="navbarNav"
        >
          <ul className="navbar-nav mx-auto"> {/* Center the nav items */}
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={scrollToHeader}
                style={{
                  cursor: "pointer",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                Home
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={scrollToServices}
                style={{
                  cursor: "pointer",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                About Us
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={scrollToExploreCars}
                style={{
                  cursor: "pointer",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                Rent a Car
              </button>
            </li>
          </ul>
        </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;